( function( $ ) {

	$( document ).ready(function(){
		$('.menu-toggle').click(function(){
			toggleMenu();
		});
	});

	function toggleMenu(){
		$('body').toggleClass('nav-open');
    $('#site-navigation').slideToggle();
	}

}( jQuery ) );